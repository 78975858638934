import React, { Component } from "react"
import { graphql } from "gatsby"

import ContactFooter from '../components/contactfooter'
import { removeSpecialSymbols } from './../util/common';
import SEO from './../components/seo'
import ScroolTop from './../components/scrolltop'
import Typed from 'react-typed';

class WorkTemplate extends Component {
    componentDidMount() {
        const isBrowser = typeof document !== "undefined";
        const AOS = isBrowser ? require("aos") : undefined;
    
        this.aos = AOS;
        this.aos.init();
    } 
    render() {
        const data = this.props.data
        const banner = data.wordpressPage.acf
        const post = data.allWordpressWpTechnologies
        const page = data.wordpressPage
        const pageacf = page.acf.content_modules_page[0]

        var oldString = banner.banner_blink_word;
        var mynewarray=oldString.split(',')
        //console.log(post);
      return (
        <>
            <SEO title="Technology" />
            <div className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <h5><span className="line"></span>{banner.banner_heading}</h5> */}
                            <h1>{banner.banner_content} <Typed strings={mynewarray} typeSpeed={120} backSpeed={50} cursorChar= {'_'} loop /></h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="technology-page">
                <div className="tech-top-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="top-content">
                                    <p>{pageacf.top_sec_left_content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tech-list-wrap">
                    <div className="container">
                        <div className="tech-list">
                            {post.edges.map((node,index) => (
                                <div className="tech-box" key={index}>
                                    <div className="title">
                                        <h1>{`${removeSpecialSymbols(node.node.title)}`}</h1>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="content">
                                                <p>{node.node.acf.technology_short_description.technology_left_content}</p>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="content">
                                                <p>{node.node.acf.technology_short_description.technology_right_content}</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="image">
                                    {node.node.featured_media !== null && node.node.featured_media.source_url !== null &&
                                        <a href={node.node.acf.technology_website_url} target="_blank"><img src={node.node.featured_media.source_url} alt={node.node.title}/></a>
                                    }
                                    </div>
                                </div>
                            ))}                            
                        </div>
                    </div>
                </div>
            </section>
            <ContactFooter />
            <ScroolTop />
        </>
        )
  }

}


export default WorkTemplate

export const pageQuery = graphql`
query($skip: Int , $limit: Int) {
    allWordpressWpTechnologies(limit: $limit, skip: $skip, sort: {order: DESC, fields: date}) {
        edges {
          node {
            id
            title
            link
            featured_media {
                source_url
            }
            acf {
                technology_website_url
                technology_short_description {
                  technology_left_content
                }
                technology_logo {
                  source_url
                }
            }
          }
        }
    }
    
    wordpressPage(wordpress_id: {eq: 15}) {        
        acf {
          banner_heading
          banner_content
          banner_blink_word
          content_modules_page {
            ... on WordPressAcf_top_section_with_left_right_content {
              top_sec_left_content
              top_sec_right_content
            }
          }
        }
      }
    
  
}
`
